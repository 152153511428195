/**
 Constante para definir el entorno en el que se encuentra la app
 Posibles valores:
 - production
 - stage
 - qa
 - dev
 - local
 **/

 export const ENV = 'stage';

 // Nota: las claves de este objeto deben coincidir
 // con el (o los) valores de la constante ENV
 export const CREDENTIALS = {
     RECAPTCHA: {
         prod: '',
         stage: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
         qa: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
         dev: '',
         local: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
     },
 };

 export default {
     CREDENTIALS,
     ENV,
 };